import { getNizza } from '../nizza-store';
import { CustomFetchConfig } from '../types';
import { Logger } from './logger';

const { coreLogger } = getNizza();
const logger = Logger.withPrefix(coreLogger, 'CustomFetch');

export async function customFetch<T = any>(
  config: CustomFetchConfig & { raw?: false },
): Promise<T>;

export async function customFetch(
  config: CustomFetchConfig & { raw: true },
): Promise<Response>;

export async function customFetch(config: CustomFetchConfig): Promise<any> {
  const {
    url,
    method = 'GET',
    body,
    raw = false,
    contentTypeJson = true,
    timeout,
    ...rest
  } = config;

  const headers = new Headers({
    ...(contentTypeJson ? { 'content-type': 'application/json' } : {}),
    ...config.headers,
  });

  try {
    const controller = new AbortController();
    const { signal } = controller;
    let timeoutId;

    const responsePromise = fetch(url, {
      method,
      headers,
      body,
      signal,
      ...rest,
    });

    if (timeout) {
      timeoutId = setTimeout(() => {
        controller.abort();
      }, timeout);
    }

    const response = await responsePromise;

    if (raw) return response;

    const json = await response.json();
    clearTimeout(timeoutId);

    if (!response.ok) {
      const err = json ? JSON.stringify(json) : response.statusText;
      throw new Error(err);
    }

    return json;
  } catch (error) {
    if (typeof url === 'string' && !url.includes('accounts/v2')) {
      // Log if the URL does not contain "accounts/v2"
      logger.debug(error);
    }

    throw error;
  }
}

declare interface ApiCall {
  url: string;
  method?: string;
  headers?: any;
  body?: any;
}

export const apiCall = <T = any>(config: ApiCall) =>
  customFetch<T>({ ...config, contentTypeJson: false, raw: false });
